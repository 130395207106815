import React from 'react'
import PropTypes, { shape } from 'prop-types'
import styles from './index.module.scss'

export const ProvenResultsAboveTheFold = ({ data }) => {
  return (
    <ul
      className={'mt-12 hidden lg:flex lg:items-center lg:justify-between'}
      id={'proven-result-list-above-the-fold'}
    >
      {data.map((item) => (
        <li key={item.tagline} className={'text-left'}>
          <b className={'text-23 font-medium leading-10'}>{item.quantity}</b>
          <p className={'text-15'}>
            {item.tagline}
            {item.sup && <sup>{item.sup}</sup>}
          </p>
        </li>
      ))}
    </ul>
  )
}

export const ProvenResultsBelowTheFold = ({ data }) => (
  <>
    <ul
      className={`hidden items-center justify-center bg-blue-135  px-8 py-16 lg:flex  ${styles.container}`}
      id="proven-result-list-below-the-fold"
    >
      {data.map((item) => (
        <li
          className="flex flex-1 flex-col items-center justify-center text-center "
          key={`${item.tagline}`}
        >
          <b className="block text-57 font-normal leading-15 ">
            {item.quantity}
          </b>
          <span className="block pb-8 text-15 lg:pb-0">
            {item.tagline}
            {item.sup && <sup>{item.sup}</sup>}
          </span>
        </li>
      ))}
    </ul>
  </>
)

ProvenResultsBelowTheFold.propTypes = {
  data: PropTypes.arrayOf(
    shape({
      quantity: PropTypes.string.isRequired,
      tagline: PropTypes.string.isRequired,
    })
  ).isRequired,
}
